<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12">
        <c-table 
          :url="'CustomerWebService/get_customer_accounts'" 
          :per-page=10 
          :fields="columns"
          :columns="['firstname', 'lastname', 'register', 'phone', 'loan_limit', 'loan_date', 'loan_amount', 'loan_balance', 'interest', 'term', 'monthly_payment', 'expiry_date']"
          :filename="'Зээлийн данс'"
          hover bordered  fixed caption="" 
        ></c-table>  
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'CustomerLoanAccounts.list',
  data: function () {
    return {
      columns: [
        { label: '№', key: 'index', class:"text-center" },
        { label: 'Төрөл', key: 'typeCode', sortable: true, sort_key:'type_code'},
        { label: 'Данс', key: 'accountNumber' },
        { label: 'Овог', key: 'lastname', sortable: true, sort_key:'lastname' },
        { label: 'Нэр', key: 'firstname', sortable: true, sort_key:'firstname' },
        { label: 'Регистр', key: 'register', sortable: true, sort_key:'register' },
        { label: 'Утас', key: 'phoneNumber', sortable: true, sort_key:'phone' },
        { label: 'Зээлийн лимит', key: 'limit', sortable: true, sort_key:'loan_limit', class:"text-right"  },
        { label: 'Зээлсэн дүн', key: 'loanAmount', sortable: true, sort_key:'loan_amount', class:"text-right" },
        { label: 'Үлдэгдэл', key: 'loanBalance', sortable: true, sort_key:'loan_balance', class:"text-right" },
        { label: 'Зээл авсан огноо', key: 'loanDate', sortable: true, sort_key:'loan_date' },
        { label: 'Хүү', key: 'interest', sortable: true, sort_key:'interest' },
        { label: 'Хугацаа', key: 'term', sortable: true, sort_key:'term' },
        { label: 'Сарын төлөлт', key: 'monthlyPayment', sortable: true, sort_key:'monthly_payment', class:"text-right" },
        { label: 'Дуусах огноо', key: 'expiryDate', sortable: true, sort_key:'expiry_date' },
      ],
    }
  },
  created: function() {
    var _buttons = []
    if( this.checkPermission('admin.customer-loan-accounts.detail')) {
        _buttons.push({
            icon:'fa fa-id-card',
            label:'',
            variant:'outline-primary',
            route: 'admin.customer-loan-accounts.detail',
            route_param: 'id'
          });
    }
    if( _buttons.length > 0) {
      this.$data.columns.push({ label: 'Үйлдэл', key: 'actions', buttons: _buttons, class:"text-center"},)
    }
  }
}
</script>
